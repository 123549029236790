export function similarProductsSheetTemplate() {
  return `
<oc-sheet-v1 hide-header no-content-padding full-height id="reptile_similar-products-sheet" class="reptile_similar-product-sheet" oc-aria-label="Ähnliche Artikel" style="display: none">
  <div class="reptile_similar-products-sheet_wrapper">
    <div class="reptile_similar-products-sheet_selectedContent">
    </div>
    <div class="reptile_similar-container">

    </div>
  </div>
</oc-sheet-v1>
  `;
}
