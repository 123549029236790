export class SimilarSheetElements {
  /*                                                                 */
  private constructor() {}

  public static SHEET_ID = "reptile_similar-products-sheet";
  public static SELECTED_CONTENT_CLASS = ".reptile_similar-products-sheet_selectedContent";
  public static SIMILAR_PRODUCTS_CONTAINER_CLASS = ".reptile_similar-container";

  static get SIMILAR_PRODUCTS_LIST(): HTMLElement | null {
    return document.querySelector(this.SIMILAR_PRODUCTS_CONTAINER_CLASS);
  }

  static get SHEET(): HTMLElement | null {
    return document.getElementById(this.SHEET_ID);
  }

  static get SELECTED_CONTENT(): HTMLElement | null {
    return document.querySelector(this.SELECTED_CONTENT_CLASS);
  }
}
