import { eventQBus } from "../types/EventQBus";
import { OnSimilarProductSheetOpen } from "../types/Tile";
import { core } from "@otto-ec/global-resources/core";
import { similarProductsSheetTemplate } from "./SimilarProductsSheetTemplate";
import { logscope } from "../utils/Logger";
import { SimilarSheetElements } from "./SimilarSheetElements";
import { sheet } from "@otto-ec/otto-components/sheet/v1";

const log = logscope.scope("similar-products-sheet");

export class SimilarProductsSheet {
  private constructor(
    private classList: DOMTokenList,
    layout: string | undefined,
    data: OnSimilarProductSheetOpen,
  ) {
    const { variationId } = data;
    this.clearSimilarProducts();
    Promise.all([this.loadSelectedContent(variationId), this.loadSimilarProducts(variationId, layout)]).then(() =>
      eventQBus.emit("reptile.similar_product_sheet.loaded"),
    );
  }

  static createSheetMarkupIfNotExist() {
    if (SimilarSheetElements.SHEET != null) return;
    const sheetPlaceholder: HTMLElement = document.getElementById(
      "reptile_similar-products-sheet-placeholder",
    ) as HTMLElement;
    if (sheetPlaceholder == null) return;
    sheetPlaceholder.outerHTML = similarProductsSheetTemplate();
  }

  static create(
    classList: DOMTokenList,
    layout: string | undefined,
    data: OnSimilarProductSheetOpen,
  ): SimilarProductsSheet | null {
    if (!data) return null;
    return new SimilarProductsSheet(classList, layout, data);
  }

  private extractHtmlFragment(response: Response) {
    if (response.status !== 200) {
      throw Error(
        `Failed to retrieve html from ${response.url} with status code ${response.status}: Error: ${response.statusText}`,
      );
    }
    return response.text();
  }

  private replaceHtmlContent(referenceNode: HTMLElement | null, html: string) {
    if (!referenceNode) {
      return;
    }
    const range = document.createRange();
    range.selectNodeContents(referenceNode);
    const fragment = range.createContextualFragment(html);
    fragment.getElementById("reptile_similar-tilelist")?.classList.add(...this.classList);
    referenceNode.appendChild(fragment);
  }

  private clearSimilarProducts() {
    const selectedContent = SimilarSheetElements.SELECTED_CONTENT;
    if (selectedContent) selectedContent.innerHTML = "";
    const dummySkeleton = document.getElementById("reptile_dummy_skeleton");
    if (dummySkeleton) {
      document
        .querySelectorAll(SimilarSheetElements.SIMILAR_PRODUCTS_CONTAINER_CLASS + " .find_tile")
        .forEach((target) => (target.innerHTML = dummySkeleton.innerHTML));
    } else {
      const referenceNode = SimilarSheetElements.SIMILAR_PRODUCTS_LIST;
      if (referenceNode) referenceNode.innerHTML = "";
    }
  }

  private async loadSimilarProducts(variationId: string, layout: string | undefined): Promise<void> {
    const queryParams: Record<string, string> = { variationId };
    if (layout) queryParams.layout = layout;
    const query = core.serialize(queryParams);

    try {
      const html = await this.fetchHtml(`/everglades/products/similar?${query}`);
      log.info("render similar products");
      this.replaceHtmlContent(SimilarSheetElements.SIMILAR_PRODUCTS_LIST, html);
    } catch (error) {
      console.error("Error loading similar products:", error);
      throw error; /*                                                       */
    }
  }

  private async loadSelectedContent(variationId: string): Promise<void> {
    try {
      const html = await this.fetchHtml(`/crocotile/tile-sheet/${variationId}`);
      log.info("render similar products");
      this.replaceHtmlContent(SimilarSheetElements.SELECTED_CONTENT, html);
    } catch (error) {
      console.error("Error loading selected content:", error);
      throw error; /*                                                       */
    }
  }

  private async fetchHtml(url: string): Promise<string> {
    const response = await fetch(url);
    return this.extractHtmlFragment(response);
  }
}

/**
 *
 *
 *
 *
 *
 */
export function registerSimilarProductSheet() {
  const sheetPlaceholder: HTMLElement = document.getElementById(
    "reptile_similar-products-sheet-placeholder",
  ) as HTMLElement;
  if (sheetPlaceholder != null) {
    const similarListClassList = sheetPlaceholder.classList;
    const layout = sheetPlaceholder.dataset.layout;
    eventQBus.on("reptile.tile.similarProductsOpen", (data: OnSimilarProductSheetOpen) => {
      SimilarProductsSheet.createSheetMarkupIfNotExist();
      sheet.open(SimilarSheetElements.SHEET_ID);
      SimilarProductsSheet.create(similarListClassList, layout, data);
    });
  } else {
    eventQBus.once("ftfind.tilelist.loaded", registerSimilarProductSheet);
  }
}
